export const STATUS =[
	{
		id: 1,name: "Mới tạo", title: ""
	},
	{
		id: 2,name: "Đã chuyển BBNT", title: "Đã chuyển thành biên bản nghiệm thu"
	},
	{
		id: 3,name: "Đã huỷ", title: ""
	},
]
export const TYPES = [
	{
		"id": 1, name: "Trong"
	},
	{
		"id": 2, name: "Ngoài"
	}
]

export const styleStatus = {
	1: "bg-info",
	2: "bg-success",
	3: "bg-gray",
}