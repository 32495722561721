import { AApi } from "@/service/AApi";
const API = new AApi(
  { url: "bienbangiaonhan" },
  {
    updateMultiple: {
      async request(ids, info) {
        return this.sdk({
          url: `/bienbangiaonhan-multiple`,
          data: {
            ids,
            info,
          },
          method: "put",
        });
      },
    },
    indexTongHop: {
      async request(params = {}) {
        return this.sdk({
          url: "/bienbangiaonhan-TongHop",
          params,
        });
      },
    },
    showTongHop: {
      async request(id, params = {}) {
        return this.sdk({
          url: `/bienbangiaonhan-TongHop/${id}`,
          params,
        });
      },
    },
    storeTongHop: {
      async request(data) {
        return this.sdk({
          url: "/bienbangiaonhan-TongHop",
          method: "post",
          data,
        });
      },
    },
    updateTongHop: {
      async request(id, data) {
        return this.sdk({
          url: `/bienbangiaonhan-TongHop/${id}`,
          data,
          method: "post",
        });
      },
    },
    destroyTongHop: {
      async request(id) {
        return this.sdk({
          url: "/bienbangiaonhan-TongHop",
          method: "delete",
        });
      },
    },
    importData: {
      async request(data) {
        return this.sdk({
          url: "/bienbangiaonhan-import",
          method: "post",
          data,
          responseType: "blob",
        });
      },
    },
    exportData: {
      async request(params) {
        return this.sdk({
          url: "/bienbangiaonhan-export",
          params,
          responseType: "blob",
        });
      },
    },
    importDataTongHop: {
      async request(data) {
        return this.sdk({
          url: "/bienbangiaonhantonghop-import",
          method: "post",
          data,
        });
      },
    },
    exportDataTongHop: {
      async request(params) {
        return this.sdk({
          url: "/bienbangiaonhantonghop-export",
          params,
          responseType: "blob",
        });
      },
    },
    // bien ban nghiem thu
    indexBbnt: {
      async request(params) {
        return this.sdk({
          url: "/bienbannghiemthu-index",
          method: 'get',
          params
        });
      },
    },
    detailBbnt: {
      async request(params) {
        return this.sdk({
          url: "/bienbannghiemthudetail",
          method: 'get',
          params
        });
      },
    },
    exportDetailBbnt: {
      async request(params) {
        return this.sdk({
          url: "/bienbanbangiao-detail-export",
          params,
          responseType: "blob",
        });
      },
    },
  }
);
export default API;

// export function index(params = {}) {
//   return request({
//     url: "/bienbangiaonhan",
//     params,
//   });
// }
// export function show(id, params = {}) {
//   return request({
//     url: `/bienbangiaonhan/${id}`,
//     params,
//   });
// }
// export function store(data) {
//   return request({
//     url: "/bienbangiaonhan",
//     method: "post",
//     data,
//   });
// }
// export function update(id, data) {
//   return request({
//     url: `/bienbangiaonhan/${id}`,
//     data,
//     method: "post",
//   });
// }


// export function destroy(id) {
//   return request({
//     url: "/bienbangiaonhan",
//     method: "delete",
//   });
// }











