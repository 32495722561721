<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :left="left"
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="menu">
      <v-tooltip bottom >
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn :loading="loading" icon v-on="menu.on">
              <v-icon>{{ icon }} </v-icon>
            </v-btn>
          </div>
        </template>
        <span>{{label}}</span>
      </v-tooltip>
    </template>
    <v-date-picker locale="vi" v-model="date" range>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false"> Đóng </v-btn>
      <v-btn text color="primary" @click="submit"> Xuất </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    value: { type: Array, default: () => [] },
    label: { type: String, default: "" },
    loading: Boolean,
    icon: { type: String, default: "mdi-microsoft-excel" },
    left: Boolean
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    date: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {
    submit() {
      if (this.date.length == 2) {
        this.$emit("save", this.date);
        this.menu = false;
      }
    },
  },
};
</script>