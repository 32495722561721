<template>
  <div>
    <div>
      <v-text-field
        append-icon="mdi-magnify"
        v-model="formSearch.search"
        label="Tìm kiếm"
        clearable
        single-line
        outlined
        dense
        hide-details
      ></v-text-field>
    </div>
    <div class="mt-4">
      <MonthPicker v-model="formSearch.date" />
    </div>
    <div class="mt-4">
      <v-text-field
        v-model="formSearch.ma_nha_cung_cap"
        label="Mã nhà cung cấp"
        clearable
        single-line
        outlined
        dense
        hide-details
      ></v-text-field>
    </div>
    <div class="mt-4">
      <v-text-field
        v-model="formSearch.ten_nha_cung_cap"
        label="Tên nhà cung cấp"
        clearable
        single-line
        outlined
        dense
        hide-details
      ></v-text-field>
    </div>

    <div class="mt-4">
      <v-autocomplete
        v-model="formSearch.type"
        :items="TYPES"
        outlined
        dense
        placeholder="Kiểu"
        item-text="name"
        item-value="id"
        hide-details
      >
      </v-autocomplete>
    </div>
  </div>
</template>
<script>
import MonthPicker from "@/components/Date/MonthPicker";
import { TYPES } from "@/constants/bienbannghiemthu";
export default {
  props: {
    value: { type: Object, default: () => ({}) },
  },
  components: {
    MonthPicker,
  },
  data: () => ({
    TYPES,
  }),
  computed: {
    formSearch: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {},
  methods: {},
};
</script>