<template>
  <PageListLayout
    icon="mdi-book-arrow-up"
    :title="title"
    subTitle="Biên bản giao nhận"
    useAdd
    titleAdd="Tạo mới"
    @add="createItem"
    @reset-filter="resetFilter"
  >
    <template slot="filter">
      <FilterComponent v-show="!isShowListTotal" v-model="formSearch" />
      <FilterTotal v-show="isShowListTotal" v-model="formSearch" />
    </template>
    <template slot="side-right">
      <div class="d-flex">
         <div class="d-flex">
          <v-btn class="mr-4" outlined @click="importData()">
            <v-icon class="mr-1">mdi-cloud-upload</v-icon>
            Import File
          </v-btn>
         </div>
        <!-- <div class="mr-4">
          <v-tooltip bottom v-if="!isShowListTotal">
            <template v-slot:activator="{ on }">
              <v-btn :loading="loading" icon v-on="on" @click="importData">
                <v-icon> mdi-import </v-icon>
              </v-btn>
            </template>
            <span>Tải lên biên bản giao nhận</span>
          </v-tooltip>
        </div> -->
        <div class="mr-4">
          <ButtonDateRange
            v-if="!isShowListTotal"
            label="Xuất biên bản giao nhận"
            v-model="date_range"
            :loading="loadingExport"
            left
            @save="exportData(date_range)"
          />
          <ButtonMonthPicker
            v-else
            nudge-left="150"
            label="Xuất biên bản giao nhận"
            v-model="date_month"
            :loading="loadingExport"
            @save="exportData(date_month)"
          />
        </div>
        <div class="mr-4">
          <v-tooltip bottom v-if="!isShowListTotal">
            <template v-slot:activator="{ on }">
              <v-btn :loading="loading" icon v-on="on" @click="convertMultiple">
                <v-icon> mdi-file-chart </v-icon>
              </v-btn>
            </template>
            <span>Chuyển biên bản nghiệm thu</span>
          </v-tooltip>
        </div>
        <div class="mr-4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :loading="loading"
                icon
                v-on="on"
                @click="isShowListTotal = !isShowListTotal"
              >
                <v-icon> mdi-swap-horizontal </v-icon>
              </v-btn>
            </template>
            <span>{{
              isShowListTotal
                ? "Hiển thị danh sách biên bản giao nhận"
                : "Hiển thị danh sách tổng hợp biên bản giao nhận theo nhà cung cấp"
            }}</span>
          </v-tooltip>
        </div>
      </div>
    </template>
    <CustomTable
      v-model="selected"
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
      :show-select="!isShowListTotal"
    >
      <template v-slot:[`item.nha_cung_cap`]="{ item }">
        <div v-if="item.nha_cung_cap">
          <strong>{{ `${item.nha_cung_cap.code} -` }}</strong>
          {{ ` ${item.nha_cung_cap.name}` }}
        </div>
      </template>
      <template v-slot:[`item.hang_hoa`]="{ item }">
        <div v-if="item.hang_hoa">
          <strong>{{ `${item.hang_hoa.code} -` }}</strong>
          {{ ` ${item.hang_hoa.name}` }}
        </div>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <div>{{ handleType(item.type) }}</div>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <div>{{ handleDateMonth(item.date) }}</div>
      </template>
      <template v-slot:[`item.gia_tri_mua`]="{ item }">
        <div>{{ handleNumber(item.gia_tri_mua) }}</div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span
          :class="`badge ${item.status && styleStatus[item.status]}`"
          :title="
            item.status &&
            item.status == 2 &&
            `Đã chuyển thành biên bản nghiệm thu`
          "
          >{{ handleShowTextStatus(item.status) }}</span
        >
      </template>
      <template v-slot:[`item.action_list`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                {{
                  item.status != 3 ? "Cập nhật" : "Chi tiết"
                }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              v-if="item.status == 1"
              @click="convertBBNT(item)"
              title="Chuyển thành biên bản nghiệm thu"
            >
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-file-chart </v-icon>
                Chuyển BBNT</v-list-item-title
              >
            </v-list-item>
            <v-list-item v-if="item.status == 1" @click="cancelItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Huỷ bỏ</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Cập nhật
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </CustomTable>
    <create-edit ref="form" @on-done="getAllList"></create-edit>
    <ImportFile ref="formImport" @on-done="getAllList"></ImportFile>
  </PageListLayout>
</template>
<script>
import CreateEdit from "./DialogCreateEdit";
import { debounce } from "lodash";
import { numberFormat } from "@/utils/moneyFormat";
import ButtonMonthPicker from "@/components/Date/button-month-picker";
// import { index, destroy, indexPhelieu } from "@/api/bienbannghiemthu.api";
import ButtonDateRange from "@/components/Date/button-date-range";
import Api from "@/api/bienbangiaonhan.api";
import { formatDate } from "@/utils/date";
import { TYPES, STATUS, styleStatus } from "@/constants/bienbangiaonhan";
import FilterComponent from "./filter";
import FilterTotal from "./filterTotal";
import { saveAs } from "file-saver";
import ImportFile from "./import-file";
export default {
  components: {
    CreateEdit,
    FilterComponent,
    FilterTotal,
    ButtonDateRange,
    ButtonMonthPicker,
    ImportFile
  },
  data() {
    return {
      loadingExport: false,
      date_month: new Date().toISOString().substr(0, 7),
      selected: [],
      date_range: [],
      styleStatus,
      showMenuDate1: false,
      formSearch: {},
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      data: [],
      btnLoading: false,
      menu: {},
      loading: false,
      isShowListTotal: false,
      search: "",
      headerTotal: [
        { text: "Tháng", value: "date", sortable: false },
        {
          text: "Mã nhà cung cấp",
          value: "nha_cung_cap.code",
          sortable: false,
        },
        {
          text: "Tên nhà cung cấp",
          value: "nha_cung_cap.name",
          sortable: false,
        },
        { text: "Kiểu", value: "type" },
        { text: "Giá trị mua", value: "gia_tri_mua", sortable: false },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center",
          width: "20",
        },
      ],
      defaultHeaders: [
        {
          text: "Ngày hoạch toán",
          value: "ngay_hoach_toan",
          width: "140px",
          sortable: false,
        },
        {
          text: "Số chứng từ",
          value: "so_chung_tu",
          width: "100px",
          sortable: false,
        },
        {
          text: "Trạng thái",
          value: "status",
          width: "100px",
          sortable: false,
        },
        {
          text: "Nhà cung cấp",
          value: "nha_cung_cap",
          width: "200px",
          sortable: false,
        },
        { text: "Kiểu", value: "type", width: "80px", sortable: false },
        { text: "Kho", value: "kho.name", width: "200px", sortable: false },
        // { text: "Mã hàng", value: "hang_hoa.code" },
        {
          text: "Hàng hoá",
          value: "hang_hoa",
          width: "200px",
          sortable: false,
        },
        { text: "SL", value: "so_luong", width: "100px", sortable: false },
        { text: "Đơn giá", value: "don_gia", width: "100px", sortable: false },
        {
          text: "Giá trị mua",
          value: "gia_tri_mua",
          width: "100px",
          sortable: false,
        },
        { text: "ĐVT", value: "hang_hoa.unit", width: "50px", sortable: false },
        {
          text: "Chiết khấu",
          value: "chiet_khau",
          width: "100px",
          sortable: false,
        },
        {
          text: "SL trả lại",
          value: "so_luong_tra_lai",
          width: "100px",
          sortable: false,
        },
        {
          text: "GT trả lại",
          value: "gia_tri_tra_lai",
          width: "100px",
          sortable: false,
        },
        {
          text: "GT giảm giá",
          value: "gia_tri_giam_gia",
          width: "120px",
          sortable: false,
        },
        // { text: "Mã thống kê", value: "ma_thong_ke" },
        // { text: "HTXL", value: "htxl" },
        {
          text: "",
          value: "action_list",
          sortable: false,
          align: "center",
          width: "20",
          fixed: true,
        },
      ],
    };
  },
  computed: {
    title() {
      return this.isShowListTotal
        ? "Tổng hợp biên bản giao nhận theo nhà cung cấp"
        : "Biên bản giao nhận";
    },
    headers() {
      if (this.isShowListTotal) {
        return this.headerTotal;
      }
      return this.defaultHeaders;
    },
    tableData() {
      return this.data.map((x, i) => {
        if (!this.isShowListTotal) {
          x.ngay_hoach_toan = formatDate(x.ngay_hoach_toan);
          x.don_gia = numberFormat(x.don_gia);
        }
        return x;
      });
    },
  },
  created() {
    this.getAllList();
  },
  watch: {
    formSearch: {
      deep: true,
      handler: debounce(async function (val) {
        this.page = 1;
        this.getAllList();
      }, 300),
    },
    isShowListTotal(val) {
      this.data = [];
      this.pageCount = 1;
      this.resetFilter();
      this.getAllList();
    },
  },
  methods: {
    resetFilter() {
      this.formSearch = this.$options.data.call(this).formSearch;
    },
    async exportData(date) {
      try {
        this.loadingExport = true;
        if (this.isShowListTotal) {
          const res = await Api.exportDataTongHop({
            date,
          });
          saveAs(new Blob([res.data]), `Chi_tiet_mua_hang_${date}.xls`);
        } else {
          const res = await Api.exportData({
            date,
          });
          saveAs(new Blob([res.data]), `Tong_hop_mua_hang.xls`);
        }
      } finally {
        this.loadingExport = false;
      }
    },
    async getAllList() {
        this.loading = true;
        let data = [];
        let params = {
          page: this.page,
          perPage: this.itemsPerPage,
          ...this.formSearch,
        };
        if (!this.isShowListTotal) {
          data = await Api.list(params);
        } else {
          data = await Api.indexTongHop(params);
          data = data.data;
        }
        this.data = data.data;
        this.pageCount = data.last_page;
        this.loading = false;
    },
    handleShowTextStatus(id) {
      let find = STATUS.find((x) => x.id == id);
      if (find) {
        return find.name;
      }
      return "";
    },
    handleNumber(number) {
      return numberFormat(number);
    },
    handleType(type) {
      let find = TYPES.find((x) => x.id == type);
      return find ? find.name : "";
    },
    changePage(val) {
      this.page = val;
      this.getAllList();
    },
    createItem() {
      if (!this.isShowListTotal) {
        this.$router.push({ path: "/kinhdoanh/themmoibienbangiaonhan" });
      } else {
        this.$refs.form.showFormAdd();
      }
    },
    handleDateMonth(date) {
      let arr = date.split("-");
      return `${arr[1]}/${arr[0]}`;
    },
    editItem(item) {
      if (!this.isShowListTotal) {
        this.$router.push({
          path: `/kinhdoanh/chitietbienbangiaonhan/${item.id}`,
        });
      } else {
        this.$refs.form.showFormEdit(item);
      }
    },
    async cancelItem(item) {
      let data = { status: 3 };
      this.$confirmBox.show({
        title: "Huỷ bỏ",
        width: 500,
        body: "Bạn có chắc chắn muốn huỷ bỏ biên bản",
        action: async () => await Api.update(item.id, data),
        onDone: () => this.getAllList(),
      });
    },
    warningThongQuan(message) {
      this.$toast.warning(message, {
        position: "top-center",
        timeout: 1500,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    async convertMultiple() {
      if (this.selected.length == 0) {
        this.warningThongQuan("Vui lòng chọn biên bản!");
        return;
      }
      let inValid = this.selected.some((x) => x.status == 3);
      if (inValid) {
        this.warningThongQuan(
          "Trong số các biên bản có biên bản đã bị huỷ bỏ!"
        );
        return;
      }
      let ids = this.selected.map((x) => x.id);
      this.$confirmBox.show({
        title: "Chuyển biên bản nghiệm thu",
        width: 500,
        body: "Bạn có chắc chắn muốn chuyển các biên bản thành biên bản nghiệm thu",
        action: async () => await Api.updateMultiple(ids, { status: 2 }),
        onDone: () => this.getAllList(),
      });
    },
    async convertBBNT(item) {
      let data = { status: 2 };
      this.$confirmBox.show({
        title: "Chuyển biên bản nghiệm thu",
        width: 500,
        body: "Xác nhận chuyển biên bản nghiệm thu",
        action: async () => await Api.update(item.id, data),
        onDone: () => this.getAllList(),
      });
    },
     importData() {
      this.$refs.formImport.showFormImport();
    },
  },
};
</script>
