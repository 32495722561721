var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageListLayout',{attrs:{"icon":"mdi-book-arrow-up","title":_vm.title,"subTitle":"Biên bản giao nhận","useAdd":"","titleAdd":"Tạo mới"},on:{"add":_vm.createItem,"reset-filter":_vm.resetFilter}},[_c('template',{slot:"filter"},[_c('FilterComponent',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShowListTotal),expression:"!isShowListTotal"}],model:{value:(_vm.formSearch),callback:function ($$v) {_vm.formSearch=$$v},expression:"formSearch"}}),_c('FilterTotal',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowListTotal),expression:"isShowListTotal"}],model:{value:(_vm.formSearch),callback:function ($$v) {_vm.formSearch=$$v},expression:"formSearch"}})],1),_c('template',{slot:"side-right"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.importData()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-cloud-upload")]),_vm._v(" Import File ")],1)],1),_c('div',{staticClass:"mr-4"},[(!_vm.isShowListTotal)?_c('ButtonDateRange',{attrs:{"label":"Xuất biên bản giao nhận","loading":_vm.loadingExport,"left":""},on:{"save":function($event){return _vm.exportData(_vm.date_range)}},model:{value:(_vm.date_range),callback:function ($$v) {_vm.date_range=$$v},expression:"date_range"}}):_c('ButtonMonthPicker',{attrs:{"nudge-left":"150","label":"Xuất biên bản giao nhận","loading":_vm.loadingExport},on:{"save":function($event){return _vm.exportData(_vm.date_month)}},model:{value:(_vm.date_month),callback:function ($$v) {_vm.date_month=$$v},expression:"date_month"}})],1),_c('div',{staticClass:"mr-4"},[(!_vm.isShowListTotal)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.loading,"icon":""},on:{"click":_vm.convertMultiple}},on),[_c('v-icon',[_vm._v(" mdi-file-chart ")])],1)]}}],null,false,827021876)},[_c('span',[_vm._v("Chuyển biên bản nghiệm thu")])]):_vm._e()],1),_c('div',{staticClass:"mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.loading,"icon":""},on:{"click":function($event){_vm.isShowListTotal = !_vm.isShowListTotal}}},on),[_c('v-icon',[_vm._v(" mdi-swap-horizontal ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.isShowListTotal ? "Hiển thị danh sách biên bản giao nhận" : "Hiển thị danh sách tổng hợp biên bản giao nhận theo nhà cung cấp"))])])],1)])]),_c('CustomTable',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Đang tải dữ liệu ...","pageCount":_vm.pageCount,"classPaging":"pt-2","show-select":!_vm.isShowListTotal},on:{"update:page":function($event){_vm.page=$event},"change-page":_vm.changePage},scopedSlots:_vm._u([{key:"item.nha_cung_cap",fn:function(ref){
var item = ref.item;
return [(item.nha_cung_cap)?_c('div',[_c('strong',[_vm._v(_vm._s(((item.nha_cung_cap.code) + " -")))]),_vm._v(" "+_vm._s((" " + (item.nha_cung_cap.name)))+" ")]):_vm._e()]}},{key:"item.hang_hoa",fn:function(ref){
var item = ref.item;
return [(item.hang_hoa)?_c('div',[_c('strong',[_vm._v(_vm._s(((item.hang_hoa.code) + " -")))]),_vm._v(" "+_vm._s((" " + (item.hang_hoa.name)))+" ")]):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.handleType(item.type)))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.handleDateMonth(item.date)))])]}},{key:"item.gia_tri_mua",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.handleNumber(item.gia_tri_mua)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("badge " + (item.status && _vm.styleStatus[item.status])),attrs:{"title":item.status &&
          item.status == 2 &&
          "Đã chuyển thành biên bản nghiệm thu"}},[_vm._v(_vm._s(_vm.handleShowTextStatus(item.status)))])]}},{key:"item.action_list",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")]),_vm._v(" "+_vm._s(item.status != 3 ? "Cập nhật" : "Chi tiết"))],1)],1),(item.status == 1)?_c('v-list-item',{attrs:{"title":"Chuyển thành biên bản nghiệm thu"},on:{"click":function($event){return _vm.convertBBNT(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-file-chart ")]),_vm._v(" Chuyển BBNT")],1)],1):_vm._e(),(item.status == 1)?_c('v-list-item',{on:{"click":function($event){return _vm.cancelItem(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-delete ")]),_vm._v(" Huỷ bỏ")],1)],1):_vm._e()],1)],1)]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")]),_vm._v(" Cập nhật ")],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('create-edit',{ref:"form",on:{"on-done":_vm.getAllList}}),_c('ImportFile',{ref:"formImport",on:{"on-done":_vm.getAllList}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }