<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="0"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        outlined
        dense
        :hide-details="hideDetail"
        :placeholder="placeholder"
        :rules="rules"
        clearable
      ></v-text-field>
    </template>
    <v-date-picker locale="vi" v-model="date" range></v-date-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    value: { type: Array, default: () => [] },
    label: { type: String, default: "" },
    placeholder: String,
    rules: { type: Array, default: () => [] },
    hideDetail: {type: [String, Boolean], default: true}
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    date: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {},
};
</script>