<template>
  <v-dialog v-model="show" persistent width="600">
    <v-card :loading="isLoading">
      <v-card-title class="headline">{{
        "Tải dữ liệu bằng tệp tin excel"
      }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <UploadFile 
              v-model="form.file" 
              ></UploadFile>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
        <v-btn
          :disabled="!form.file"
          :loading="isLoading"
          color="primary"
          @click="importBBGN"
        >
          <v-icon left>mdi-cloud-upload</v-icon>
          Tải lên
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Api from "@/api/bienbangiaonhan.api";
import UploadFile from "@/components/upload/UploadFile";
import JsonToFromData from "@/utils/JsonToFormData";
export default {
  components: {
    UploadFile,
  },
  data: () => ({
    isLoading: false,
    show: false,
    form: {
      file: null,
    },
  }),
  watch: {
    show(val) {
      if (!val) {
        this.form = this.$options.data.call(this).form;
      }
    },
  },
  methods: {
    showFormImport() {
      this.show = true;
      if (this.$refs["files"]) {
        this.$refs["files"].value = null;
      }
    },
    async importBBGN() {
      this.isLoading = true;
      let data = JsonToFromData({ ...this.form });
      const res = await Api.importDataTongHop(data);
      this.isLoading = false;
      this.$emit("on-done");
      this.show = false;
    },
  },
};
</script>
