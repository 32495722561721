<template>
  <v-dialog v-model="show" persistent width="700">
    <v-card>
      <v-card-title class="headline">{{
        edit ? "CẬP NHẬT BIÊN BẢN GIAO NHẬN" : "THÊM BIÊN BẢN GIAO NHẬN"
      }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <div class="label-form">Tháng</div>
              <MonthPicker
                v-model="form.date"
                :rules="required"
                hideDetail="auto"
                placeholder="Tháng"
              ></MonthPicker>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Nhà cung cấp</div>
              <v-autocomplete
                v-model="form.nha_cung_cap_id"
                :items="khachhangs"
                placeholder="Nhà cung cấp"
                outlined
                dense
                prepend-inner-icon="mdi-code-tags"
                :rules="required"
                :disabled="edit ? true : false"
                hide-details="auto"
                item-text="name"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="label-form">Kiểu</div>
              <v-autocomplete
                prepend-inner-icon="mdi-folder-account"
                v-model="form.type"
                :items="types"
                outlined
                dense
                placeholder="Kiểu"
                item-text="name"
                item-value="id"
                :rules="required"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="6">
              <div class="label-form">Giá trị mua</div>
              <v-text-field
                type="number"
                v-model="form.gia_tri_mua"
                placeholder="Giá trị mua"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
                :rules="required"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="label-form">Ghi chú</div>
              <v-textarea
                v-model="form.description"
                placeholder="Ghi chú"
                outlined
                dense
                prepend-inner-icon="mdi-note"
                no-resize
                hide-details="auto"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
        <v-btn
          :loading="btnLoading"
          color="primary"
          @click="addItem"
          v-if="!edit"
        >
          <v-icon left>mdi-plus</v-icon>
          Thêm mới
        </v-btn>
        <v-btn :loading="btnLoading" color="primary" @click="editItem" v-else>
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { TYPES } from "@/constants/bienbangiaonhan";
import MonthPicker from "@/components/Date/MonthPicker";
import KHACHHANG from "@/api/khachhang";
import Api from "@/api/bienbangiaonhan.api";
export default {
  props: {
    types: { type: Array, default: () => TYPES },
  },
  components: {
    MonthPicker,
  },
  data: () => ({
    show: false,
    edit: false,
    btnLoading: false,
    form: {},
    nameRules: [(v) => !!v || "Tên không thể bỏ trống"],
    codeRules: [(v) => !!v || "Mã không thể bỏ trống"],
    required: [(v) => !!v || "Không thể bỏ trống"],
    khachhangs: [],
    isLoadingKhachHang: false,
  }),
  computed: {},
  mounted() {
    this.fetchKhachHang();
  },
  methods: {
    async fetchKhachHang() {
      try {
        this.isLoadingKhachHang = true;
        const res = await KHACHHANG.getAllCustomers({ select: ["id", "name", "code"] });
        this.khachhangs = res.data;
      } finally {
        this.isLoadingKhachHang = false;
      }
    },
    showFormAdd() {
      this.show = true;
      this.edit = false;
      this.form = {};
    },
    async showFormEdit(data) {
      this.edit = true;
      this.show = true;
      this.form = { ...data };
      this.form.nha_cung_cap_id = parseInt(this.form.nha_cung_cap_id)
    },
    async addItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          await Api.storeTongHop(this.form);
          this.$emit("on-done");
          this.$toast.info("Thêm thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } finally {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
    async editItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          await Api.updateTongHop(this.form.id, this.form);
          this.$emit("on-done");
          this.$toast.info("Cập nhật thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } finally {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
  },
};
</script>
