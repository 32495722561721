<template>
  <v-menu
    ref="menu_date_month"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        :placeholder="placeholder"
        :rules="rules"
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        outlined
        dense
        :hide-details="hideDetail"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      @input="menu = false"
      :readonly="readonly"
      type="month"
      locale="vi"
    ></v-date-picker>
    <!-- <v-date-picker
      readonly
      v-model="date"
      type="month"
      locale="vi"
      no-title
      scrollable

    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false"> Huỷ bỏ </v-btn>
      <v-btn text color="primary" @click="$refs.menu_date_month.save(date)">
        đồng ý
      </v-btn>
    </v-date-picker> -->
  </v-menu>
</template>
<script>
export default {
  props: ["value", "label", "placeholder", "rules", "hideDetail", "readonly"],
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data: () => ({
    menu: false,
  }),
  methods: {
    save(date) {
      this.$refs.menu_date_month.save(date);
    },
  },
};
</script>